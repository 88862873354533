import React from 'react'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'

class LanguageSwitcher extends React.Component {
  constructor(props) {
    super(props)
    this.state = { language: props.i18n.language || 'vi' }
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ language: nextProps.i18n.language })
  }

  handleChangeLanguage(lng) {
    const { i18n } = this.props
    i18n.changeLanguage(lng)
  }

  renderLanguageChoice({ code, label }, index) {
    const klass = classNames({
      'active': this.state.language === code,
    })

    return (
      <span key={code}>
        <span
          className={klass}
          onClick={() => this.handleChangeLanguage(code)}
        >
          {` ${label} `}
        </span>
        {index === 2 ? '' : '/'}
      </span>
    )
  }

  render() {
    const languages = [
      { code: 'en', label: 'EN' },
      { code: 'vi', label: 'VI' },
      { code: 'ja', label: 'JP' },
    ]

    return (
      <div className="language-switcher">
        {languages.map((language, index) => this.renderLanguageChoice(language, index))}
      </div>
    )
  }
}

export default withTranslation()(LanguageSwitcher)
