import React from 'react'
import { withTranslation } from 'react-i18next'

const Footer = ({ t }) => (
  <footer className="footer">
    <div className="container grid-xl">
      <div className="columns">
        <div className="column col-5 col-lg-12">
          <h2>Contact</h2>
          <div className="region-contact">
            <h3>{t('footer.company_vn_name')}</h3>
            <strong>{t('footer.address')}</strong>: Floor 8, 57-59 Hồ Tùng Mậu street, Ben Nghe Ward, 1 District, Ho Chi Minh city, Vietnam<br />
            <strong>{t('footer.tel')}</strong>: <a href="tel:+819016818184">+81 9016818184</a><br />
            <strong>{t('footer.email')}</strong>: <a href="mailto:info@jp-cultivate.com">info@jp-cultivate.com</a><br />
            <strong>{t('footer.website')}</strong>: <a href="http://jp-cultivate.com" target="_blank" rel="noopener noreferrer">http://jp-cultivate.com</a>
          </div>
        </div>
        <div className="column col-7 col-lg-12">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d979.8790857226186!2d106.70331782921217!3d10.771708999520314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTDCsDQ2JzE4LjIiTiAxMDbCsDQyJzEzLjkiRQ!5e0!3m2!1sen!2s!4v1604371917510!5m2!1sen!2s" width="100%" height="400" frameBorder="0" style={{ border:0 }} allowFullScreen=""></iframe>
        </div>
      </div>
    </div>
    <p className="copyright text-center">©2020 Feel Saigon. All Right Reserved.</p>
  </footer>
)

export default withTranslation()(Footer)
