import React from 'react'
import { Link } from 'gatsby'
import { withTranslation } from 'react-i18next'

import LanguageSwitcher from './LanguageSwitcher'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.subMenu = React.createRef()
    this.subMenu2 = React.createRef()
    this.handleMouseOver = this.handleMouseOver.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleMouseOver2 = this.handleMouseOver2.bind(this)
    this.handleMouseLeave2 = this.handleMouseLeave2.bind(this)
  }

  handleMouseOver() {
    if (this.subMenu.current.classList.contains('opened')) return
    this.subMenu.current.classList.add('opened')
  }

  handleMouseLeave() {
    this.subMenu.current.classList.remove('opened')
  }

  handleMouseOver2() {
    if (this.subMenu2.current.classList.contains('opened')) return
    this.subMenu2.current.classList.add('opened')
  }

  handleMouseLeave2() {
    this.subMenu2.current.classList.remove('opened')
  }

  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        <header className="header">
          <div className="container grid-lg">
            <div className="navbar">
              <section className="navbar-section"></section>
              <section className="navbar-center">
                <img src={require('../images/logo.png')} alt="Vietnam Cultivate Co.,LTD Logo" />
              </section>
              <section className="navbar-section">
                <LanguageSwitcher />
              </section>
            </div>
            <h1 className="sub-logo">Feel Saigon Co.,LTD</h1>
            <p className="tagline text-center">
              {t('header.tagline')}
            </p>
            <div className="text-center">
              <a className="subsidiary" href="http://jp-cultivate.com" target="_blank">http://jp-cultivate.com</a>
            </div>
            <p className="text-center">
              <a className="subsidiary" href="tel:+84903316737">0903316737</a>
            </p>
          </div>
        </header>
        <div className="header-menu">
          <div className="container grid-xl">
            <ul className="tab tab-block">
              <li className="tab-item">
                <Link to="/">{t('header.home')}</Link>
              </li>
              <li className="tab-item">
                <Link to="/joc">{t('header.joc')}</Link>
              </li>
              <li
                className="tab-item p-relative"
                onMouseOver={this.handleMouseOver2}
                onMouseLeave={this.handleMouseLeave2}
              >
                <Link to="/cashew-nuts">{t('header.cashew_nuts')}</Link>
                <ul
                  className="sub-menu"
                  ref={this.subMenu2}
                >
                  <li>
                    <Link to="/tools">{t('header.tools')}</Link>
                  </li>
                </ul>
              </li>
              <li
                className="tab-item p-relative"
                onMouseOver={this.handleMouseOver}
                onMouseLeave={this.handleMouseLeave}
              >
                <Link to="/products">{t('header.products')}</Link>
                <ul
                  className="sub-menu"
                  ref={this.subMenu}
                >
                  <li>
                    <Link to="/process-production">{t('header.process_production')}</Link>
                  </li>
                  <li>
                    <Link to="/process-exporting">{t('header.process_exporting')}</Link>
                  </li>
                  <li>
                    <Link to="/tutorial">{t('header.tutorial')}</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(Header)
