import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from '../locales/en.json'
import ja from '../locales/ja.json'
import vi from '../locales/vi.json'

const resources = {
  en: { translation: en },
  ja: { translation: ja },
  vi: { translation: vi },
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'vi',

    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      wait: true,
    },
  })

export default i18n
